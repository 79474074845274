import Button from '@atoms/Button/Button';
import HeaderBottomDesktop from '@svg/header-bottom-desktop.svg';
import HeaderBottomMobile from '@svg/header-bottom-mobile.svg';
import VitalisLogo from '@svg/logo.svg';
import useStore from '@utils/useStore';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import styles from './Header.module.scss';
import Menu from './Menu';
import MenuToggle from './MenuToggle';
import SearchToggle from './SearchToggle';

const MENU_ID = 'header-main-menu';
const SEARCH_ID = 'header-search-bar';

function Header() {
  const menuToggleRef = useRef();
  const menuRef = useRef();
  const router = useRouter();
  const {
    store: {
      page: { typename = 'Content' } = {},
      config: { mainMenu },
      isJobsDomain,
    },
  } = useStore();

  const [menuIsShown, setMenuIsShown] = useState(false);
  const [shownSubmenuId, setShownSubmenuId] = useState();
  const [searchIsShown, setSearchIsShown] = useState(false);

  const toggleMenu = () => {
    setMenuIsShown(!menuIsShown);
    setSearchIsShown(false);
  };

  const toggleSubMenu = ({ id } = {}) => {
    setShownSubmenuId(id);
    setSearchIsShown(false);
  };

  // Close menu when clicked outside of it
  const handleClickOutsideMenu = e => {
    if (
      menuIsShown &&
      !menuRef?.current.contains(e.target) &&
      !menuToggleRef?.current.contains(e.target)
    ) {
      toggleMenu();
    }
  };

  // Close submenu when clicked outside of it
  const handleClickOutsideSubMenu = e => {
    if (shownSubmenuId && !menuRef?.current.contains(e.target)) {
      toggleSubMenu();
    }
  };

  // Close menus when changing route
  useEffect(() => {
    if (menuIsShown) {
      toggleMenu();
    }
    if (shownSubmenuId) {
      toggleSubMenu();
    }

    setSearchIsShown(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutsideMenu);

    return function cleanup() {
      document.body.removeEventListener('click', handleClickOutsideMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsShown]);

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutsideSubMenu);

    return function cleanup() {
      document.body.removeEventListener('click', handleClickOutsideSubMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownSubmenuId]);

  // Dynamically import SearchSuggestionsModal to improve performance
  const SearchSuggestionsModal = dynamic(
    () => import('./SearchSuggestionsModal'),
    {
      ssr: false,
    }
  );

  return (
    <>
      <header className={classNames(styles.header, styles[typename])}>
        <div className={classNames(styles.container, 'u-container')}>
          <Link href="/" className={styles.logoLink} aria-label="Home">
            <VitalisLogo className={styles.logo} />
          </Link>

          {mainMenu?.length ? (
            <>
              <MenuToggle
                MENU_ID={MENU_ID}
                menuToggleRef={menuToggleRef}
                onClick={toggleMenu}
                menuIsShown={menuIsShown}
              />
              <Menu
                mainMenu={mainMenu}
                MENU_ID={MENU_ID}
                menuRef={menuRef}
                menuIsShown={menuIsShown}
                toggleMenu={toggleMenu}
                shownSubmenuId={shownSubmenuId}
                toggleSubMenu={toggleSubMenu}
              />
            </>
          ) : (
            <span className={styles.spacer} />
          )}

          <div
            className={classNames(
              styles.searchButtonContainer,
              searchIsShown && styles.searchButtonContainerIsActive
            )}
          >
            <SearchToggle
              SEARCH_ID={SEARCH_ID}
              searchIsShown={searchIsShown}
              toggleSearch={() => setSearchIsShown(!searchIsShown)}
            />
          </div>

          <SearchSuggestionsModal
            id={SEARCH_ID}
            show={searchIsShown}
            onClose={() => setSearchIsShown(false)}
          />

          {isJobsDomain && (
            <Button
              label="Past Vitalis bij jou?"
              href="/matchmaker"
              size="small"
              extraClasses={styles.matchMakerButton}
            />
          )}
        </div>
        <HeaderBottomMobile className={styles.headerBottomMobile} />
        <HeaderBottomDesktop
          className={classNames(
            styles.headerBottomDesktop,
            shownSubmenuId && styles.subMenuIsShown
          )}
        />
      </header>
      {(shownSubmenuId || searchIsShown) && (
        <span className={styles.backdrop} />
      )}
    </>
  );
}

export default Header;
