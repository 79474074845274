import { ITEMS_PER_PAGE, LOCALES } from '@constants';
import { createPreprClient } from '@preprio/nodejs-sdk';
import { clamp } from '@utils/clamp';
import { isCorporateDomain } from '@utils/getDomain';
import { formatISO } from 'date-fns';
import { print } from 'graphql';

import {
  AGENDA_IFRAME_QUERY,
  AGENDA_OVERVIEW_PAGE_QUERY,
  CONTENT_PAGES_SLUGS_QUERY,
  CORPORATE_HOME_PAGE_QUERY,
  EVENT_PAGES_QUERY,
  FIELD_OF_WORK_QUERY,
  getContentPageQuery,
  getEventPageQuery,
  getFooterQuery,
  getlocationPageQuery,
  getMainMenuQuery,
  getNewsPageQuery,
  JOB_ALERT_PAGE_QUERY,
  JOBS_HOME_PAGE_QUERY,
  LOCATION_DETAILS_QUERY,
  LOCATION_OVERVIEW_PAGE_QUERY,
  LOCATION_PAGES_QUERY,
  MATCHMAKER_PAGE_QUERY,
  MATCHMAKER_RESULTS_PAGE_QUERY,
  NEWS_OVERVIEW_PAGE_QUERY,
  NEWS_PAGES_QUERY,
  NEWS_PAGES_SLUGS_QUERY,
  QUICK_MENU_ITEMS_QUERY,
  REFERRAL_PAGE_QUERY,
  REFERRAL_THANKS_PAGE_QUERY,
  RELATED_EVENTS_QUERY,
  RELATED_NEWS_QUERY,
  RELATED_STORIES_QUERY,
  STORY_OVERVIEW_PAGE_QUERY,
  STORY_PAGE_QUERY,
  STORY_PAGES_QUERY,
  VACANCY_OVERVIEW_PAGE_QUERY,
  VACANCY_THANKS_PAGE_QUERY,
} from './queries';
import getAllSlugsQuery, { getAllSlugsTotalQuery } from './queries/allSlugs';

const preprClient = createPreprClient({
  token: process.env.PREPR_CORPORATE_ACCESS_TOKEN, // You can find one in your Prepr environment
  baseUrl: 'https://graphql.prepr.io/graphql',
  timeout: 30000,
});

async function fetchPrepr({ query, variables = {} }) {
  const { host, customerId, locale = 'nl', preview } = variables;
  const limit = variables.limit || ITEMS_PER_PAGE;
  const skip = (Number(variables.page || 1) - 1) * limit;
  const corporateDomain = isCorporateDomain(host);

  // Make the right prepr connection based on domainname and preview var
  let preprToken = process.env.PREPR_CORPORATE_ACCESS_TOKEN;
  if (corporateDomain && preview) {
    preprToken = process.env.PREPR_CORPORATE_PREVIEW_ACCESS_TOKEN;
  } else if (!corporateDomain && !preview) {
    preprToken = process.env.PREPR_JOBS_ACCESS_TOKEN;
  } else if (!corporateDomain && preview) {
    preprToken = process.env.PREPR_JOBS_PREVIEW_ACCESS_TOKEN;
  }

  let gqlQuery = query;
  if (typeof query === 'function') {
    // Some queries require different fields based on the environment we are in,
    // We do this by running a function that returns the correct query based on the domain variables
    gqlQuery = query({
      isCorporateDomain: corporateDomain,
      isJobsDomain: !corporateDomain,
    });
  }

  return preprClient
    .graphqlQuery(print(gqlQuery))
    .graphqlVariables({ ...variables, limit, skip, locale: LOCALES[locale] })
    .token(preprToken)
    .customerId(customerId)
    .fetch()
    .then(res => {
      if (res.errors?.length) {
        console.error(res);
        throw new Error(res.errors[0].message);
      }
      return res;
    })
    .catch(error => {
      console.error('[fetchPrepr error]', { error });
    });
}

const minimumChunkSize = 25;
const maximumChunkSize = 100;
const defaultAmountOfAsyncRequests = 25;

/**
 * Get the limit for the amount of items to fetch per request
 *
 * Example: Prepr has 1500 items. The default amount of async requests is 25. The limit will be 50.
 * It has a minimum of 25 and a maximum of 100.
 */
const calculateLimit = total =>
  clamp(
    minimumChunkSize,
    Math.ceil(total / defaultAmountOfAsyncRequests),
    maximumChunkSize
  );

export async function fetchAllPrepr({
  totalQuery,
  query,
  variables = {},
  itemsAccessor,
  totalAccessor,
}) {
  // Get the total amount of items
  const res = await fetchPrepr({
    query: totalQuery,
    variables: {
      ...variables,
    },
  });

  if (!res) return { items: [], total: 0 };

  const total = totalAccessor(res);
  if (!total) return { items: [], total: 0 };

  const limit = calculateLimit(total);
  const pages = Math.ceil(total / limit);

  // Chunk the requests and store them in an array
  const promises = Array.from({ length: pages }, (_, i) =>
    fetchPrepr({
      query,
      variables: {
        ...variables,
        limit,
        page: i + 1,
      },
    })
  );

  // Make all requests asynchronously
  const results = await Promise.all(promises);

  // Combine all items into one array
  const items = results.reduce((acc, result) => {
    if (!result) return acc;
    return acc.concat(itemsAccessor(result) || []);
  }, itemsAccessor(res) || []);

  return { items, total };
}

export { preprClient };

/*
  Global config
*/
export async function getConfig({ host, locale, preview }) {
  const variables = { host, locale, preview };
  const corporateDomain = isCorporateDomain(host);
  let configQueries = [
    fetchPrepr({
      query: getMainMenuQuery,
      variables,
    }),
    fetchPrepr({
      query: getFooterQuery,
      variables,
    }),
  ];

  if (corporateDomain) {
    configQueries = [
      ...configQueries,
      fetchPrepr({
        query: QUICK_MENU_ITEMS_QUERY,
        variables,
      }),
    ];
  }
  const res = await Promise.allSettled(configQueries).then(results =>
    results.map(result => result.value?.data || result.value?.error)
  );

  return {
    mainMenu: res[0]?.MainMenu?.navigationMenu || null,
    footer: res[1]?.Footer || null,
    quickMenuItems: res[2]?.HomePage?.quickMenuItems || [],
  };
}

/*
  All slugs
*/
function extractSlugItems(res) {
  return [
    ...(res?.data?.ContentItems?.items || []),
    ...(res?.data?.EventPages?.items || []),
  ];
}

export async function getAllSlugs({ host }) {
  const res = await fetchAllPrepr({
    totalQuery: getAllSlugsTotalQuery,
    query: getAllSlugsQuery,
    variables: { host, untilDate: formatISO(new Date()) },
    itemsAccessor: data => extractSlugItems(data),
    totalAccessor: data =>
      (data?.data?.ContentItems?.total || 0) +
      (data?.data?.EventPages?.total || 0),
  });
  return res.items;
}

/*
  Corporate home
*/
export async function getCorporateHomePage({
  host,
  locale,
  customerId,
  preview,
}) {
  const res = await fetchPrepr({
    query: CORPORATE_HOME_PAGE_QUERY,
    variables: { host, locale, customerId, preview },
  });
  return res?.data?.HomePage;
}

/*
  Jobs home
*/
export async function getJobsHomePage({ host, locale, customerId, preview }) {
  const res = await fetchPrepr({
    query: JOBS_HOME_PAGE_QUERY,
    variables: { host, locale, customerId, preview },
  });

  return res?.data?.HomePage;
}

/*
  Match maker pages
*/
export async function getMatchmakerPage({ host, locale, customerId, preview }) {
  const res = await fetchPrepr({
    query: MATCHMAKER_PAGE_QUERY,
    variables: { host, locale, customerId, preview },
  });

  return res?.data?.MatchmakerPage;
}

export async function getMatchmakerResultsPage({
  host,
  locale,
  customerId,
  preview,
}) {
  const res = await fetchPrepr({
    query: MATCHMAKER_RESULTS_PAGE_QUERY,
    variables: { host, locale, customerId, preview },
  });

  return {
    page: res?.data?.MatchmakerResultsPage,
    fieldsOfWork: res?.data?.FieldsOfWork?.items,
  };
}

/*
  News
*/
export async function getAllNewsPagesSlugs({ host }) {
  const res = await fetchPrepr({
    query: NEWS_PAGES_SLUGS_QUERY,
    variables: { host },
  });
  return res?.data?.NewsPages?.items;
}

export async function getNewsOverviewPage({
  host,
  page,
  locale,
  customerId,
  preview,
  categories,
}) {
  const res = await fetchPrepr({
    query: NEWS_OVERVIEW_PAGE_QUERY,
    variables: { host, page, locale, customerId, preview, categories },
  });
  return {
    page: res?.data?.NewsOverviewPage,
    items: res?.data?.NewsPages.items,
    total: res?.data?.NewsPages.total,
    categories: res?.data?.NewsCategories.items,
  };
}

export async function getNewsPage({ host, slug, locale, customerId, preview }) {
  const res = await fetchPrepr({
    query: getNewsPageQuery,
    variables: { host, slug, locale, customerId, preview },
  });
  return res?.data?.NewsPage;
}

export async function getNewsPages({
  host,
  page,
  locale,
  customerId,
  preview,
  categories,
}) {
  const res = await fetchPrepr({
    query: NEWS_PAGES_QUERY,
    variables: { host, page, locale, customerId, preview, categories },
  });

  return {
    total: res?.data?.NewsPages.total,
    items: res?.data?.NewsPages.items,
  };
}

export async function getRelatedNews({
  host,
  id,
  locale,
  customerId,
  preview,
  categories,
}) {
  const res = await fetchPrepr({
    query: RELATED_NEWS_QUERY,
    variables: { host, id, locale, customerId, preview, categories },
  });

  return {
    sameCategoryNews: res?.data?.SameCategoryNews,
    upcomingNews: res?.data?.UpcomingNews,
  };
}

/*
  Locations
*/
export async function getLocationOverviewPage({
  host,
  locale,
  customerId,
  preview,
  categories,
}) {
  const res = await fetchPrepr({
    query: LOCATION_OVERVIEW_PAGE_QUERY,
    variables: { host, locale, customerId, preview, categories },
  });

  return {
    page: res?.data?.LocationOverviewPage,
    total: res?.data?.LocationPages.total,
    items: res?.data?.LocationPages.items,
    categories: res?.data?.LocationCategories.items,
  };
}

export async function getLocationPages({
  host,
  locale,
  customerId,
  preview,
  categories,
}) {
  const res = await fetchPrepr({
    query: LOCATION_PAGES_QUERY,
    variables: { host, locale, customerId, preview, categories },
  });

  return {
    total: res?.data?.LocationPages.total,
    items: res?.data?.LocationPages.items,
  };
}

export async function getLocationPage({
  host,
  slug,
  locale,
  customerId,
  preview,
}) {
  const res = await fetchPrepr({
    query: getlocationPageQuery,
    variables: { host, slug, locale, customerId, preview },
  });
  return res?.data?.LocationPage;
}

export async function getLocationDetails({ host, afasCode }) {
  const res = await fetchPrepr({
    query: LOCATION_DETAILS_QUERY,
    variables: { host, afasCode },
  });
  return res?.data?.LocationPages;
}

/*
  Agenda
*/
export async function getAgendaOverviewPage({
  host,
  page,
  locale,
  customerId,
  preview,
  categories,
  locations,
  untilDate = formatISO(new Date()),
  untilDateHighlighted = formatISO(new Date()),
}) {
  const res = await fetchPrepr({
    query: AGENDA_OVERVIEW_PAGE_QUERY,
    variables: {
      host,
      page,
      locale,
      customerId,
      preview,
      categories,
      locations,
      untilDate,
      untilDateHighlighted,
    },
  });

  return {
    page: res?.data?.AgendaOverviewPage,
    total: res?.data?.EventPages.total,
    items: res?.data?.EventPages.items,
    categories: res?.data?.EventCategories.items,
    locations: res?.data?.LocationPages.items,
    highlightedEventPages: res?.data?.HighlightedEventPages.items,
  };
}

export async function getAgendaIframe({ host, location }) {
  const untilDate = formatISO(new Date());
  const res = await fetchPrepr({
    query: AGENDA_IFRAME_QUERY,
    variables: {
      host,
      location: location ? `/${location}` : null,
      untilDate,
    },
  });

  return {
    items: res?.data?.EventPages.items,
  };
}

export async function getEventPages({
  host,
  page,
  locale,
  customerId,
  preview,
  categories,
  locations,
  untilDate = formatISO(new Date()),
}) {
  const res = await fetchPrepr({
    query: EVENT_PAGES_QUERY,
    variables: {
      host,
      page,
      locale,
      customerId,
      preview,
      categories,
      locations,
      untilDate,
    },
  });

  return {
    total: res?.data?.EventPages.total,
    items: res?.data?.EventPages.items,
  };
}

export async function getEventPage({
  host,
  slug,
  locale,
  customerId,
  preview,
}) {
  const res = await fetchPrepr({
    query: getEventPageQuery,
    variables: { host, slug, locale, customerId, preview },
  });
  return res?.data?.EventPage;
}

export async function getRelatedEvents({
  host,
  locale,
  customerId,
  limit,
  untilDate,
  highlightedOnly,
  locations,
}) {
  const res = await fetchPrepr({
    query: RELATED_EVENTS_QUERY,
    variables: {
      host,
      locale,
      customerId,
      limit,
      untilDate,
      highlightedOnly: highlightedOnly || undefined,
      locations,
    },
  });

  return {
    upcomingEvents: res?.data?.events,
  };
}

/*
  Content pages
*/
export async function getAllContentPagesSlugs({ host }) {
  const res = await fetchPrepr({
    query: CONTENT_PAGES_SLUGS_QUERY,
    variables: { host },
  });
  return res?.data?.ContentPages?.items;
}

export async function getContentPage({
  host,
  slug,
  locale,
  customerId,
  preview,
}) {
  const res = await fetchPrepr({
    query: getContentPageQuery,
    variables: { host, slug, locale, customerId, preview },
  });
  return res?.data?.ContentPage;
}

/*
  Vacancies
*/
export async function getVacancyOverviewPage({
  host,
  locale,
  customerId,
  preview,
}) {
  const res = await fetchPrepr({
    query: VACANCY_OVERVIEW_PAGE_QUERY,
    variables: { host, locale, customerId, preview },
  });

  return {
    page: res?.data?.VacancyOverviewPage,
  };
}

/*
  Job alert page
*/
export async function getJobAlertPage({ host, customerId, preview }) {
  const res = await fetchPrepr({
    query: JOB_ALERT_PAGE_QUERY,
    variables: { host, customerId, preview },
  });
  return res?.data?.JobAlertPage;
}

/**
 * Stories
 */
export async function getStoryOverviewPage({
  host,
  page,
  locale,
  customerId,
  preview,
  fieldsOfWork,
}) {
  const res = await fetchPrepr({
    query: STORY_OVERVIEW_PAGE_QUERY,
    variables: { host, page, locale, customerId, preview, fieldsOfWork },
  });

  return {
    page: res?.data?.StoryOverviewPage,
    items: res?.data?.StoryPages.items,
    total: res?.data?.StoryPages.total,
    fieldsOfWork: res?.data?.FieldsOfWork.items,
  };
}

export async function getStoryPage({
  host,
  slug,
  locale,
  customerId,
  preview,
}) {
  const res = await fetchPrepr({
    query: STORY_PAGE_QUERY,
    variables: { host, slug, locale, customerId, preview },
  });
  return res?.data?.StoryPage;
}

export async function getStoryPages({
  host,
  page,
  locale,
  customerId,
  preview,
  fieldsOfWork,
}) {
  const res = await fetchPrepr({
    query: STORY_PAGES_QUERY,
    variables: { host, page, locale, customerId, preview, fieldsOfWork },
  });
  return {
    total: res?.data?.StoryPages.total,
    items: res?.data?.StoryPages.items,
  };
}

export async function getRelatedStories({
  host,
  id,
  locale,
  customerId,
  preview,
  fieldsOfWork,
}) {
  const res = await fetchPrepr({
    query: RELATED_STORIES_QUERY,
    variables: { host, id, locale, customerId, preview, fieldsOfWork },
  });

  return {
    sameFieldsOfWorkStories: res?.data?.SameFieldsOfWorkStories,
    upcomingStories: res?.data?.UpcomingStories,
  };
}

/*
  Field of work
*/
export async function getFieldOfWork({
  recruiterCode,
  functionCategoryCode,
  applicationFormCode,
  host,
  customerId,
  preview,
}) {
  const res = await fetchPrepr({
    query: FIELD_OF_WORK_QUERY,
    variables: {
      recruiterCode,
      functionCategoryCode,
      applicationFormCode,
      host,
      customerId,
      preview,
    },
  });

  return {
    fieldOfWork: res?.data?.FieldsOfWork.items[0],
    storyPages: res?.data?.StoryPages,
    recruiter: res?.data?.Recruiters,
    applicationProcess: res?.data?.ApplicationProcesses,
  };
}

/* Vacancy thanks page */
export async function getVacancyThanksPage({ host, customerId, preview }) {
  const res = await fetchPrepr({
    query: VACANCY_THANKS_PAGE_QUERY,
    variables: { host, customerId, preview },
  });

  return res?.data?.VacancyThanksPage;
}

/* Referral page */
export async function getReferralPage({ host, customerId, preview }) {
  const res = await fetchPrepr({
    query: REFERRAL_PAGE_QUERY,
    variables: { host, customerId, preview },
  });

  return { page: res?.data?.ReferralPage };
}

/* Referral thanks page */
export async function getReferralThanksPage({ host, customerId, preview }) {
  const res = await fetchPrepr({
    query: REFERRAL_THANKS_PAGE_QUERY,
    variables: { host, customerId, preview },
  });

  return res?.data?.ReferralThanksPage;
}
