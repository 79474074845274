import PropTypes from 'prop-types';

import ctaType from './ctaType';
import newsType from './newsType';

const relatedNewsType = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  modifier: PropTypes.string,
  cta: ctaType,
  selection: PropTypes.arrayOf(newsType),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export default relatedNewsType;
